import React, { useRef, useState } from "react";
import {
	Badge,
	Button,
	Card,
	CardBody,
	CardSubtitle,
	CardText,
	CardTitle,
	Form,
	Input,
	InputGroup,
	InputGroupText,
	Modal,
	ModalBody,
	ModalFooter,
	Table,
} from "reactstrap";
import textContent from "../../TextContent.json";
import { Position, UserProfile } from "../../Models";
import sendEmail from "./../../services/EmailService";
import { fileUpload } from "./../../services/FileService";

const Careers: React.FC = () => {
	const [modal, setModal] = useState(false);
	//const [resume, setResume] = useState<File | null>(null);
	//const [resumePath, setResumePath] = useState<string>("");
	const [userProfile, setUserProfile] = useState<UserProfile>({
		resume: null,
		name: "",
		linkedin: "",
		email: "",
	});
	const toggle = () => setModal(!modal);
	const [applyPosition, setApplyPosition] = useState<Position>({
		id: 0,
		title: "",
		type: "",
		description: "",
		skills: [],
		location: "",
	});

	const userProfileComplete = (userProfile: UserProfile) => {
		console.log(userProfile);
		return (
			userProfile.resume !== null &&
			userProfile.name !== "" &&
			userProfile.linkedin !== "" &&
			userProfile.email !== ""
		);
	};
	const submitApplication = async (applyPosition: Position) => {
		if (userProfileComplete(userProfile)) {
			try {
				const response = await fileUpload(userProfile.resume as File);
				console.log("File uploaded successfully!!", response);
				const uploadedResumeDetails = response.data.file_info;
				if (uploadedResumeDetails !== "") {
					try {
						const emailResponse = await sendEmail({
							from_name: userProfile.name,
							position_id: applyPosition.id,
							resume_location: uploadedResumeDetails.webViewLink,
							linked_in_location: userProfile.linkedin,
							candidate_email: userProfile.email,
						});
						console.log("Email sent successfully!!", emailResponse);
					} catch (emailError) {
						console.error("Error sending email:", emailError);
					}
				}
				toggle();
			} catch (error) {
				console.error("Error sending email:", error);
			}
		} else {
			console.error("User profile is incomplete.");
		}
	};
	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			//setResume(file);
			setUserProfile({ ...userProfile, resume: file });
		}
	};

	return (
		<div>
			<div id="logo-header" className="logo-header">
				<img
					src="/logo/logo_transparent.png"
					alt="Logo"
					onClick={() => (window.location.href = "/home")}
				/>
				Careers
			</div>
			<div className="new-row">
				{textContent.positions.length === 0 ? (
					<h2 style={{ textAlign: "center" }}>No Positions Available</h2>
				) : (
					""
				)}
				{textContent.positions.map((position: Position) => (
					<Card body style={{ marginTop: "3%", margin: "3% 3% 3% 3%" }}>
						<CardTitle tag="h5">{position.title}</CardTitle>
						<CardSubtitle className="mb-2 text-muted" tag="h6">
							{position.type}
						</CardSubtitle>
						<CardText>{position.description}</CardText>
						<Button
							className="button"
							color="primary"
							style={{ width: "min-content" }}
							onClick={() => {
								setApplyPosition(position);
								toggle();
							}}
						>
							Apply
						</Button>
					</Card>
				))}
				{applyPosition !== null ? (
					<Modal isOpen={modal} toggle={toggle}>
						<ModalBody>
							<CardBody>
								<CardTitle tag="h5">{applyPosition.title}</CardTitle>
								<CardSubtitle className="mb-2 text-muted" tag="h6">
									{applyPosition.type}
								</CardSubtitle>
								<CardText>{applyPosition.description}</CardText>
							</CardBody>
							<InputGroup style={{ marginTop: "25px" }}>
								<InputGroupText>Full Name</InputGroupText>
								<Input
									type="text"
									placeholder="Full Name"
									onChange={(e) => {
										setUserProfile({
											...userProfile,
											name: e.target.value,
										});
									}}
								/>
							</InputGroup>
							<InputGroup style={{ marginTop: "25px" }}>
								<InputGroupText>Linked-In</InputGroupText>
								<Input
									type="text"
									placeholder="Linked-In profile URL"
									onChange={(e) => {
										setUserProfile({
											...userProfile,
											linkedin: e.target.value,
										});
									}}
								/>
							</InputGroup>
							<InputGroup style={{ marginTop: "25px" }}>
								<InputGroupText>Email</InputGroupText>
								<Input
									type="text"
									placeholder="Email Address"
									onChange={(e) => {
										setUserProfile({
											...userProfile,
											email: e.target.value,
										});
									}}
								/>
							</InputGroup>
							<Input
								style={{ marginTop: "25px" }}
								type="file"
								placeholder="Upload Resume"
								onChange={handleFileChange}
							/>
						</ModalBody>
						<ModalFooter>
							<Button
								color="primary"
								disabled={
									userProfile.resume === null ||
									userProfile.email === null ||
									userProfile.linkedin === null ||
									userProfile.name === null
										? true
										: false
								}
								onClick={() => {
									submitApplication(applyPosition);
								}}
							>
								Submit
							</Button>{" "}
							<Button color="secondary" onClick={toggle}>
								Cancel
							</Button>
						</ModalFooter>
					</Modal>
				) : (
					""
				)}
			</div>
		</div>
	);
};

export default Careers;
