import emailjs from '@emailjs/browser';
import { EmailParams } from '../Models';

const SERVICE_ID = process.env.REACT_SERVICE_ID || '';
const TEMPLATE_ID = process.env.REACT_TEMPLATE_ID || '';
const USER_ID = process.env.REACT_USER_ID || '';

export default function sendEmail(emailParams: EmailParams) {
     console.log("Email params received:", emailParams);
     const serviceId = SERVICE_ID; // Replace with your service ID
     const templateId = TEMPLATE_ID; // Replace with your template ID
     const userId = USER_ID; // Replace with your user ID

     return emailjs.send(serviceId, templateId, emailParams, userId);
}