import React from "react";
import "./Info.css";
import textContent from "../../TextContent.json";

const Info: React.FC = () => {
	return (
		<div>
			<div id="logo-header" className="logo-header">
				<img src="/logo/logo_transparent.png" alt="Logo" />
				AI Adoptions
				<span id="sub-quote">Making AI Usable</span>
			</div>

			<div id="info-content">
				<p>{textContent.messages.homepage}</p>
			</div>
			<div id="link-group">
				<p className="normal">
					Careers
					<img
						src="/box-arrow-up-right.svg"
						style={{ height: "30px", margin: "0px 10px 3px 10px" }}
						alt="careers"
						onClick={() => (window.location.href = "/careers")}
					/>
				</p>
				<p className="normal">
					Email
					<a href="mailto:nikhilkumar@aiadoptions.ai">
						<img
							src="/envelope.svg"
							style={{ height: "32px", margin: "0px 10px 3px 10px" }}
							alt="email"
							onClick={() => (window.location.href = "/careers")}
						/>
					</a>
				</p>
			</div>

		</div>
	);
};

export default Info;
