import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";
import Info from "./components/Info/Info";
import Careers from "./components/Careers/Careers";

function App() {
	return (
		<div id="root">
			<Router>
				<Routes>
					<Route path="/" element={<Info />} />
					<Route path="/home" element={<Info />} />
					<Route path="/careers" element={<Careers />} />
				</Routes>
			</Router>
			<footer id="footer" className="footer">
				<p>NSK AI Adoptions {new Date().getFullYear()}</p>
			</footer>
		</div>
	);
}

export default App;
