import axios from 'axios';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'http://localhost:8080';
export async function fileUpload(file: File) {
     const formData = new FormData();
     formData.append('file', file);

     try {
          return await axios.post(`${API_ENDPOINT}/api/apply`, formData, {
               headers: {
                    'Content-Type': 'multipart/form-data'
               }
          });
     } catch (error) {
          console.error('Error uploading file:', error);
          throw error;
     }
}